$(document).ready(function () {
    $(".myoffer-cta2").click(function () {
        var promoCode = $(this).closest('.promo-card-cta').find('#promoCode').val();
        if(clipPromo(promoCode)) {
            $('#offerAcc .owl-item').each(function(index, item) {
                $(item).find('button.myoffer-cta2').removeClass('hide');
            });
            $('#offerAcc .owl-item').each(function(index, item) {
                $(item).find('button.myoffer-cta3').addClass('hide');
            });
            $(this).parents('#my-offers-carousel').find('.promo-card').removeClass('active');
            $(this).closest(".promo-card").toggleClass("active");
            $(this).addClass('hide');
            $(this).siblings(".myoffer-cta3").removeClass('hide');
        }
    });
    $(".myoffer-cta3").click(function () {
        var promoCode = $(this).closest('.promo-card-cta').find('#promoCode').val();
        if(unClipPromo(promoCode)){
            $('#offerAcc .owl-item').each(function(index, item) {
                $(item).find('button.myoffer-cta2').removeClass('hide');
            });
            $('#offerAcc .owl-item').each(function(index, item) {
                $(item).find('button.myoffer-cta3').addClass('hide');
            });
            $(this).addClass('hide');
            $(this).closest(".promo-card").toggleClass("active");
            $(this).parents('#my-offers-carousel').find('.promo-card').removeClass('active');
        }
    });
    $('#offerAcc').on('shown.bs.collapse', function () {
        $('body').addClass('posFixed');
        $('.my-offers').addClass('active');
        let distanceTop = $('.my-offers').height() + $('.my-offers').offset().top + 10;
        let myOfferParent = $(".my-offers.active");
        myOfferParent.css("--my-offers-top", `${distanceTop}px`);
    });
    $('#offerAcc').on('hide.bs.collapse', function () {
        $('.my-offers').removeClass('active');
        $('body').removeClass('posFixed');
    });
    $('.promoDetailsLink').on('click', function(e){
        e.preventDefault();
        var promoCard = $(this).closest('.promo-card');
        $('#promotionDetailsModal').modal('show');
        $('#promotionDetailsModal').addClass('offerModal');
        $('#promotionDetailsModal').find('.promDetails').html(promoCard.find('#promotionDetails').val());
    });
    if ($('#offerAcc').find('.promo-card').length > 0){
        $('.my-offers').removeClass('hide');
        $('.my-offers-count').text($('#offerAcc').find('.promo-card').length);
    } else {
        $('.my-offers').addClass('hide');
    }

    $(".my-offers-icon-toggle").click(function() {
        $(this).find('.img-plus').toggle();
        $(this).find('.img-minus').toggle();
    });
});
$(document).on('click', '#offerAccordian', function() {
    $("#my-offers-carousel").owlCarousel({
        loop: false,
        autoWidth: true,
        nav:false,
    });
});

function clipPromo(promoCode) {
    var result = false;
    $.ajax({
        url: ACC.config.encodedContextPath + "/clip-promo?promoCode=" + promoCode,
        type: "get",
        async: false,
        contentType: "application/json",
        success: function (data) {
            result = data;
        },
        error: function() {
            result = false;
        },
        complete: function() {}
    });
    return result;
}

function unClipPromo(promoCode) {
    var result = false;
    $.ajax({
        url: ACC.config.encodedContextPath + "/unclip-promo?promoCode=" + promoCode,
        type: "get",
        async: false,
        contentType: "application/json",
        success: function (data) {
            result = data;
        },
        error: function() {
            result = false;
        },
        complete: function() {}
    });
    return result;
}
// Click of overlay to close offers component
$(document).on('click', '.my-offers-overlay', function() {
    $('#offerAccordian').trigger('click');
});
